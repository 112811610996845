<template>
  <div class="container">
    <h1>내가 식품 브랜드라면?</h1>
    <h2>Food Brand MBTI Test</h2>
    <img src="/image/intro.png" alt="농심 상품 캐릭터들" />
    <button class="cta" @click="startTest">
      테스트 시작하기
      <div class="status">현재 총 {{count}}명이 참여했어요!</div>
    </button>
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/firestore";
export default {
  name: "Home",
  data() {
    return {
      count: 0
    };
  },
  created() {
    const docRef = this.$firestore.collection("user").doc("fbti");
    docRef.get().then(doc => {
      const { finishCount } = doc.data();
      this.count = finishCount;
    });
  },
  methods: {
    startTest() {
      const increment = firebase.firestore.FieldValue.increment(1);
      const docRef = this.$firestore.collection("user").doc("fbti");
      docRef.update({ startCount: increment });
      this.$router.push({
        name: "Test"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
h1 {
  font-weight: bold;
  font-family: Recipekorea;
  font-size: 28px;
  line-height: 36px;
}
h2 {
  font-size: 12px;
  line-height: 14px;
  margin: 0 auto 50px;
}
img {
  margin-bottom: 100px;
}
</style>
